<template>
  <div class="news-detail" :class="{'no-head': !showHead}">
    <div class="title" v-if="showHead">
      {{ info.title }}
    </div>
    <div class="share" v-if="showHead">
      <div class="resources">
        <span>{{ info.createTime | formatDate }}</span>
      </div>
    </div>
    <div class="clear" v-if="showHead"></div>
    <div id="content" class="content" v-html="info.newsContent">
    </div>
  </div>
</template>
<script>
export default {
  name: 'RightNewsDetail',
  props: {
    info: {
      type: Object,
      default: null
    },
    showHead: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    // 返回年月日
    formatDate (string, state) {
      if (state == 'dateMonth') {
        return string.substring(5, 10);
      }
      if (state == 'dateMonthTwo') {
        return string.substring(5, 10).replace(/-/g, '/');
      }
      if (state == 'dateMonthTime') {
        return string.substring(5, 19).replace(/-/g, '/');
      }
      if (state == 'dateHour') {
        const newHour = string.substring(11, 16).split(':');
        let hour = newHour[0];
        let minute = newHour[1];
        if (hour.length == 1) {
          hour = '0' + hour;
        }
        if (minute.length == 1) {
          minute = '0' + minute;
        }
        return hour + ':' + minute;
      }
      if (state == 'dateYear') {
        const newData = string.substring(0, 10).split('-');
        return newData[0] + '年' + newData[1] + '月' + newData[2] + '日';
      }
      if (string) {
        return string.substring(0, 10).replace(/-/g, '/');
      } else {
        return '';
      }
    }
  },
}
</script>
<style lang='less' scoped>
.news-detail {
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 35px;
    text-align: center;
  }
  .share {
    height: 32px;
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  .content {
    margin-top: 30px;
    font-size: 18px;
    line-height: 30px;
  }
}
.no-head {
  padding: 16px 0;
  .content {
    margin-top: 0;
  }
}
</style>