<template>
  <div class="section">
    <div class="main-title">
      <p>{{ $enum.getDescByValue('NEWS_SUB_TYPE_ENUM', type) }}</p>
    </div>
    <div class="section-policy">
      <RightNewsDetail :info="info" :showHead="false"/>
    </div>
    <div class="check-detail" @click="toDetail">点击查看更多 ></div>
  </div>
</template>
<script>
import { newsApi } from '@/api/news';
import { NEWS_TYPE_ENUM } from '@/constant/common';
import RightNewsDetail from '@/components/right-news-detail/index.vue';
export default {
  name: '',
  components: {
    RightNewsDetail
  },
  filters: {},
  mixins: [],
  props: {
    type: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      NEWS_TYPE_ENUM,
      leftNews: {},
      newsList: [],
      newsCategory: {},
      info: {}
    };
  },
  computed: {
    bgStyle(){
      if (this.leftNews.coverPic) {
        return {background:`url(${this.leftNews.coverPic}) no-repeat center`,backgroundSize: '540px;'};
      } else {
        return {};
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.queryNewsList();
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {
    async queryNewsList() {
      try {
        const data = {
          pageNum: 1,
          pageSize: 4,
          categoryId: this.type,
          newsTypeId:this.type
        };
        const result = await newsApi.queryNewsList(data);
        const list = result.data.list;
        if (!this.lodash.isEmpty(list)) {
          this.getDetail(list[0].newsId)
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getDetail (newsId) {
      let result = await newsApi.getNewsDetail(newsId);
      this.info = result.data;
    },
    toDetail() {
      this.$router.push({
        path: '/news/news-list',
        query: {
          type: this.type,
          newsId: this.info.newsId
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
.section {
  width: 590px;
  height: 460px;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 18px 20px 20px 20px;
  .main-title {
    width: 100%;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      width: 100%;
      height: 33px;
      line-height: 33px;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      color: #1c2634;
      position: relative;
      padding-left: 21px;
    }
    a {
      height: 20px;
      line-height: 20px;
      color: #13619f;
    }
  }
  .section-policy {
    width: 100%;
    height: calc(100% - 82px);
    overflow: hidden;
    margin-top: 12px;
    border-top: 1px solid #ededed;
  }
  .check-detail {
    margin-top: 12px;
    width: 100%;
    height: 20px;
    text-align: end;
    line-height: 20px;
    color: #13619f;
    cursor: pointer;
  }
}
</style>
