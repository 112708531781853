<!--
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-20 10:39:11
 * @LastEditors: 马明昌 m17319036829@163.com
 * @LastEditTime: 2024-10-28 09:53:28
-->
<template>
  <div class="wrapper">
    <DefaultHeader  @login="scrollToLogin"/>
    <div class="banner">
      <div class="user-center-box">
        <div class="user-container">
          <div class="user" :class="{'no-login': !userInfo}">
            <AccountContainer v-if="!userInfo"
                              ref="accountContainer"
                              :show-wx-login-type="true"
                              @success="loginSuccess"></AccountContainer>
            <div v-else>
              <Avatar class="user-header" size="75"
                      :src="userInfo.avatar || 'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/zrlpa/default-avatar.png'"/>
              <div class="logged">
                <p class="user-name">
                  {{ userInfo.name || userInfo.nickName }}
                </p>
                <p class="user-phone">
                  {{ userInfo.phone | phoneReplace }}
                </p>
                <a v-if="lastCourseRecord" class="study-record" href="javascript:void(0)" @click="goWatchLastCourse()">
                  <p class="text-two-row-hide">
                    <span>上次学到：</span>{{ lastCourseRecord.courseName }}-{{ lastCourseRecord.courseResourceTitle }}
                  </p>
                </a>
                <div class="study-center-btn" @click="openPc('learn-center/sign')">
                  进入学习中心
                </div>
                <div class="logout">
                  <span @click="logout">退出登录></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="center-box">
      
      <div class="news-area">
        <!-- 政策法规 -->
        <Policy ref="policyRef" :type="NEWS_SUB_TYPE_ENUM.KAI_FA_XIE_HUI.value"/>
        <Policy ref="governmentRef" :type="NEWS_SUB_TYPE_ENUM.SHOU_FA_FEN_HUI.value"/>
      </div>
      <!-- <div class="course-area">
        <CourseList :allCourseList="allCourseList" @login="scrollToLogin"/>
      </div> -->
    </div>
    


    <IndexFooter/>
    <!-- 微信登录弹窗 -->
    <WxLogin ref="wxLogin"></WxLogin>
    <!-- 报名流程弹窗 -->
    <SignUpProcessModal ref="signUpProcessModal"></SignUpProcessModal>
    <!-- 听课须知弹窗 -->
    <NotesForLecturesModal ref="notesForLecturesModal"></NotesForLecturesModal>
    <!-- 系统通知弹窗 -->
    <SystemCloseModal ref="systemCloseModal"></SystemCloseModal>
  </div>
</template>

<script>
import DefaultHeader from '@/components/default-header';
import userHeader from '@/assets/image/index/user-header.png';
import IndexFooter from '@/components/index-footer';
import { courseApi } from '@/api/course';
import { COURSE_SALE_TYPE_ENUM } from '@/constant/course';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import WxLogin from '@/components/account/wx-login';
import { SEARCH_TYPE_ENUM, NEWS_TYPE_ENUM } from '@/constant/common';
import { WEB_PAGE_TDK_PAGE_ENUM } from '@/constant/seo.js';
import AccountContainer from '@/components/account/components/account-container';
import SignUpProcessModal from '@/components/sign-up-process-modal';
import NotesForLecturesModal from '@/components/notes-for-lectures-modal';
import SystemCloseModal from '@/components/system-close-modal';
import LearnProcess from '@/components/learn-process';
import NewsIndex from './index/components/news-index';
import FreeSection from './index/components/free-section';
import CourseSection from './index/components/course-section';
import BookSection from './index/components/book-section';
import Policy from './index/components/policy';
import Government from './index/components/government';
import VoiceSection from './index/components/voice-section';
import courseMixin from './course/components/mixins';
import { newsApi } from '@/api/news';
import CourseList from './index/components/course-list/index';
import { NEWS_SUB_TYPE_ENUM } from '@/constant/common.js';
export default {
  name: 'Index',
  components: {
    DefaultHeader,
    NewsIndex,
    FreeSection,
    CourseSection,
    BookSection,
    VoiceSection,
    IndexFooter,
    WxLogin,
    AccountContainer,
    Policy,
    Government,
    SignUpProcessModal,
    LearnProcess,
    NotesForLecturesModal,
    SystemCloseModal,
    CourseList
  },
  filters: {
    phoneReplace(value) {
      if (!value) {
        return '';
      }
      ;
      return value.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
    }
  },
  mixins: [courseMixin],
  props: {},
  data() {
    return {
      head: {},
      SEARCH_TYPE_ENUM,
      NEWS_SUB_TYPE_ENUM,
      showLogin: false,
      searchList: this.$enum.getValueDescList('SEARCH_TYPE_ENUM'),
      searchVisible: false,
      searchType: SEARCH_TYPE_ENUM.COURSE.value,
      searchForm: {
        searchWord: null
      },
      saleType: COURSE_SALE_TYPE_ENUM.COURSE_FRANCO.value,
      userHeader,
      lastCourseRecord: null,
      allCourseList: []
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    bgImg() {
      return this.$store.state.user.systemPic.middleLoginBgImg;
    },
    bgImgStyle() {
      if (this.bgImg) {
        return {
          backgroundImage: `url(${this.bgImg})`
        };
      } else {
        return {};
      }
    }
  },
  watch: {
    // 监听用户登录状态改变
    userInfo: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          // this.queryLastCourseRecord();
        } else {
          this.lastCourseRecord = null;
        }
      }
    }
  },
  created() {
  },
  mounted() {
    // this.showSystemCloseModal();
    window.onload = () => {
      this.noticeInit();
      setTimeout(()=>{
        if(window.qimoChatClick){
          window.qimoChatClick();
        }
      },1000);
    };
    this.getSubTypeList();
    this.setHeader();
    // this.queryCourseList();
  },
  methods: {
    // 退出登录
    logout() {
      this.$Modal.confirm({
        title: '提示',
        content: '是否确认退出登录？',
        onOk: () => {
          this.$store.commit('user/clearUserInfo');
        }
      });
    },
    // 设置header
    async setHeader() {
      this.head = await this.$store.dispatch('seo/getSeoDetail', WEB_PAGE_TDK_PAGE_ENUM.HOME.value);
    },
    // 进入题库页面
    goQuestionPage() {
      this.$pcRouter.push({ path: 'center/my-question' });
    },
    // 微信登录
    onWxLogin() {
      this.$refs.wxLogin.showModal();
    },
    // 监听回车按下事件
    listenEnterKeyDown() {
      document.onkeydown = (e) => {
        const event = e || window.event;
        if (event.keyCode == 13) {
          this.searchQuery();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown() {
      document.onkeydown = undefined;
    },
    // 公告轮播
    noticeInit() {
      const noticeSwiper = new Swiper('#noticeSwiper', {
        loop: true,
        autoplay: 1500,
        direction: 'vertical',
        simulateTouch: false
      });
      // 移入暂停，移出滚动
      $('#noticeSwiper').hover(
        function () {
          noticeSwiper.stopAutoplay();
        },
        function () {
          noticeSwiper.startAutoplay();
        }
      );
    },

    // 点击登录
    openLogin() {
      const params = {
        isShow: true,
        showType: ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
      };
      this.$store.commit('user/changeAccountModal', params);
    },

    // 点击注册
    openRegister() {
      const params = {
        isShow: true,
        showType: ACCOUNT_SHOW_TYPE.REGISTER.value
      };
      this.$store.commit('user/changeAccountModal', params);
    },

    // 跳转上一版PC页面
    openPc(path, query) {
      this.$pcRouter.push({ path, query });
    },

    // 显示搜索下拉框
    onVisibleChange(visible) {
      this.searchVisible = visible;
    },

    // 选择搜索类型
    selectSearchType(type) {
      this.searchType = type;
    },
    // 登录成功
    loginSuccess() {
      // 跳转完善个人信息页面
      // this.openPc('learn-center/my-info');
    },
    // 点击搜索
    searchQuery() {
      const searchWord = this.searchForm.searchWord;
      if (!searchWord) {
        this.$Message.warning('请输入搜索内容');
        return;
      }
      let path;
      if (this.searchType == SEARCH_TYPE_ENUM.COURSE.value) {
        path = '/course';
      } else if (this.searchType == SEARCH_TYPE_ENUM.BOOK.value) {
        path = '/book';
      } else {
        return;
      }
      this.$router.push({
        path,
        query: { searchWord }
      });
    },

    // 查询学员最后一次学习记录
    async queryLastCourseRecord() {
      const result = await courseApi.queryLastCourseRecord();
      this.lastCourseRecord = result.data;
    },
    async goWatchLastCourse() {
      // 校验课程有没有下架
      const res = await courseApi.judgeCourseOffShelf(this.lastCourseRecord.courseResourceId);
      if (res.data) {
        this.$Modal.info({
          title: '提示',
          content: '课程已下架，请到学习中心学习其他课程。',
          onOk: () => {
            this.openPc('learn-center/my-course');
          }
        });
        return;
      }
      this.$pcRouter.push({
        path: 'video-play',
        query: {
          courseId: this.lastCourseRecord.courseId,
          resourceId: this.lastCourseRecord.courseResourceId
        }
      });
    },
    showSignUpProcessModal() {
      this.$refs.signUpProcessModal.showModal = true;
    },
    showNotesForLecturesModal() {
      this.$refs.notesForLecturesModal.showModal = true;
    },
    // 系统通知弹窗
    showSystemCloseModal() {
      this.$refs.systemCloseModal.showModal = true;
    },
    async getSubTypeList() {
      try {

      } catch (e) {
        console.log(e);
        this.$smartSentry.captureException(e);
      }
    },
    async queryCourseList() {
      this.allCourseList = [];
      let categoryList = this.$enum.getValueDescList('CATEGORY_TYPE_ENUM');
      categoryList.map(async item => {
        try {
          let params = {
            categoryId: item.value,
            limit: 3
          };
          let result = await courseApi.queryCourseList(params);
          let courseList = result.data;
          if (!this.lodash.isEmpty(courseList)) {
            this.allCourseList.push({
              categoryName: item.desc,
              categoryId: item.value,
              courseList: courseList
            })
          }
        } catch(e) {
          this.$smartSentry.captureException(e);
        }
      })
    },
    scrollToLogin() {
      $(document).scrollTop(0);
      this.$Message.warning('请先登录');
    }
  },
  head() {
    return this.head;
  }
  
};
</script>
<style lang="less" scoped>
@import "./index/index.less";
</style>
